import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {combineLatest, defer, iif, of, Subscription} from 'rxjs';
import {catchError, debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AppConfig} from '../../../app.config';
import {NavigationService} from '../../../shared/services/navigation.service';
import {AppState, getAuthUserIsPublic} from '../../../state-management/reducers';

interface SearchResult {
  total: number;
  elem: SearchElement[];
}

interface SearchElement {
  label: string;
  info: string;
  id: number;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
}

@Component({
  selector: 'pm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements AfterViewInit, OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatInput) inputField: MatInput;

  folded = false;
  searchTextControl: FormControl = new FormControl('');
  searchTypeControl: FormControl = new FormControl('text');
  searchResultsSubscription: Subscription;
  loading = false;
  displayedColumns = ['label', 'path'];
  dataSource = new MatTableDataSource<SearchElement>();
  activeSearchElement: any = null;
  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  constructor(private userService: UserService, private appConfig: AppConfig, private http: HttpClient,
    private navigationService: NavigationService, private snackBar: MatSnackBar,
    private translate: TranslateService, private store$: Store<AppState>) {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId]?.toLocaleLowerCase() ?? '';
  }

  ngOnInit() {
    this.searchResultsSubscription = combineLatest([this.searchTextControl.valueChanges.pipe(startWith('')),
      this.searchTypeControl.valueChanges.pipe(startWith('text'))])
      .pipe(map(([searchString, type]) => [this.removeShortWords(searchString, 3), type]), debounceTime(250),
        tap(() => this.loading = true), switchMap(([searchString, type]) => {
          const url = environment.api + this.userService.domain + '/search';
          return iif(() => !!searchString, defer(() => this.http.post<SearchResult>(url, {
            searchString: searchString,
            searchType: type
          })).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === 403) {
              this.snackBar.open(this.translate.instant('dialog.search.error.too-many-results'));
            }
            return of({elem: []})
          }), map(searchResult => searchResult.elem)), of([]));
        }), tap(() => this.loading = false)).subscribe(searchElements => {
        this.dataSource.data = searchElements || []
      });
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
    this.isPublicSubscription.unsubscribe();
//    (interact(this.element.nativeElement) as any).unset();
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.inputField.focus());
    this.dataSource.sort = this.sort;
  }

  navigateToNode(searchElement: SearchElement) {
    this.activeSearchElement = searchElement.id;
    this.appConfig.highlightNext = true;
    this.navigationService.navigateToNode(searchElement.id, this.isPublic);
  }

  onFoldedChange(folded: boolean) {
    this.folded = folded;
  }

  searchTypeChange() {

  }

  private removeShortWords(sentence: string, minLength: number): string {
    return (sentence && sentence.trim().split(/\s+/) || ['']).filter(word => word.length >= minLength).join(' ');
  }
}
