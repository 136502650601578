import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  MatTooltipDefaultOptions
} from '@angular/material/tooltip';
import {InteractEvent} from '@interactjs/types';
import {TranslateService} from '@ngx-translate/core';
import {v1} from 'uuid';
import {Link} from '../../../shared/model/link';
import {convertToNormalizedSize, convertToPixelSize, Size} from '../../../shared/model/process';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {RenderedProcess} from '../../../shared/model/rendered/rendered-process';
import {Shape} from '../../../shared/model/shape';
import {TemplateId} from '../../../shared/model/template-id';
import {CheckTouchService} from '../../../shared/services/check-touch.service';
import {
  DATA_NODE_IS_TEMPLATE_ROOT,
  DATA_POINTER_OVER,
  InteractService
} from '../../../shared/services/interact.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {PointerMoveListenerService} from '../../../shared/services/pointer-move-listener.service';
import {defaultTrumbowygOptions, JQueryTrumbowyg, TrumbowygDirective} from '../../../shared/trumbowyg.directive';
import {ActivationType} from '../../../state-management/reducers/tree.reducer';

const MICRO_TASK = Promise.resolve(null);

class Padding {
  public constructor(public left: number, public right: number, public top: number, public bottom: number) {
  }
}

const PM_PROCESS_PADDINGS: Map<Shape, Padding> = new Map(
  [[Shape.ELLIPSE, new Padding(15, 15, 2, 2)], [Shape.LEFT_ARROW, new Padding(30, 2, 2, 2)],
    [Shape.RIGHT_ARROW, new Padding(2, 30, 2, 2)], [Shape.UP_ARROW, new Padding(2, 2, 20, 2)],
    [Shape.DOWN_ARROW, new Padding(2, 2, 2, 20)], [Shape.RECTANGLE, new Padding(2, 2, 2, 2)],
    [Shape.NONE, new Padding(2, 2, 2, 2)]]);

const PROCESS_ID_PREFIX = 'process-';

const customTooltipOptions: MatTooltipDefaultOptions = {
  ... MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  disableTooltipInteractivity: true
}
@Component({
  selector: 'pm-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions}
  ]
})
export class ProcessComponent implements OnInit, OnDestroy, OnChanges {
  @Input() context: RenderedProcess & Partial<TemplateId>;
  @Input() size: Size;
  @Input() dragDropActive = true;
  @Input() isAuthor = false;
  @Input() isTemplateRoot = false;
  @Output() activationChange = new EventEmitter<{ element: RenderedElement, type: ActivationType }>();
  @Output() updated = new EventEmitter<{ element: RenderedElement, content: ActivationType }>();
  @Output() resized = new EventEmitter<{ element: RenderedElement, size: Size }>();
  @Output() linkClick = new EventEmitter<{ element: RenderedElement, link: Link | string }>();
  @Output() elementClick = new EventEmitter<RenderedElement>();

  @ViewChild('processWrapper', {static: true}) processDiv: ElementRef;
  @ViewChild(TrumbowygDirective, {static: true}) trumbowyg: TrumbowygDirective;

  width: number;
  height: number;
  measuredHeight: number;
  shape: Shape = Shape.NONE;
  padding: Padding = new Padding(0, 0, 0, 0);

  isTouching$ = this.touchDetect.touching$;

  readonly trumbowygOptions: JQueryTrumbowyg.Options = {...defaultTrumbowygOptions};

  private hasNodes = false;
  private dragoverTimer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private zone: NgZone,
    private navigationService: NavigationService, private touchDetect: CheckTouchService,
    private interactService: InteractService, private translateService: TranslateService,
              private pointerMoveListener: PointerMoveListenerService) {
  }

  @HostBinding('id') get processId() {
    return PROCESS_ID_PREFIX + (this.context && this.context.id || v1());
  }

  @HostBinding('attr.' + DATA_NODE_IS_TEMPLATE_ROOT) get templateRoot() {
    return this.isTemplateRoot;
  }

  get isActive(): boolean {
    return this.calculateActive(this.context.activation);
  }

  get isEditingText(): boolean {
    return this.context.activation === 'textEdit';
  }

  get paddingString(): string {
    return `${this.padding.top}px ${this.padding.right}px ${this.padding.bottom}px ${this.padding.left}px`;
  }

  get owner(): string {
    if (this.isActive) {
      return '';
    }

    const owner = this.context?.owner || '';
    if (owner && this.context.ownerInherited) {
      return this.translateService.instant('process.owner.inherited', {owner: owner});
    } else {
      return owner;
    }
  }

  get hasArrow(): boolean {
    return ((this.hasNodes || this.context.hasUnloadedChildren) && this.context.nodeType === 'process') ||
      this.context.nodeType === 'mainpage';
  }

  @HostBinding('style.width') get pxWidth() {
    return this.width + 'px';
  }

  private get processQuery(): string {
    const id = '#' + this.processId;
    return `${id} .process`;
  }

  private get overlayQuery(): string {
    const id = '#' + this.processId;
    return `${id} pm-link-overlay`;
  }

  processClicked($event) {
    this.prevent($event);
    this.elementClick.emit(this.context);
  }

  onResizeMove(event: InteractEvent) {
    this.zone.run(() => {
      this.width = event['rect'].width;
      this.height = this.measuredHeight = event['rect'].height;
      this.changeDetectorRef.markForCheck();
    });
  };

  onResizeEnd() {
    this.measuredHeight = 0;
    this.updateHeight();
    this.resized.emit({
      element: this.context,
      size: convertToNormalizedSize({
        width: this.width,
        height: this.height
      })
    });
  };

  ngOnInit() {
    this.measuredHeight = this.height;
  }

  contextMenu($event) {
    if(!this.pointerMoveListener.isTouch) {
      this.toggleMenu($event);
    }
  }

  toggleMenu($event) {
    if (!this.isEditingText) {
      this.prevent($event);
      this.toggleActive();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const processChanges = changes['context'];
    if (!!processChanges) {
      this.shape = this.context?.style?.shape || Shape.NONE;
      this.hasNodes = this.context?.children?.length > 0;
      this.padding = PM_PROCESS_PADDINGS.get(this.shape);
      if (!processChanges.firstChange && processChanges.previousValue.activation === 'textEdit' &&
        processChanges.currentValue.activation !== 'textEdit') {
        this.updateElement();
      }
      if (processChanges?.previousValue?.activation !== 'highlight' && processChanges?.currentValue?.activation === 'highlight') {
        const target = this.processDiv.nativeElement;
        if (!!target.scrollIntoView) {
          target.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth'
          });
        }
      }
    }

    if (!!changes['size']) {
      const pixelSize = convertToPixelSize(this.size);
      this.width = pixelSize.width;
      this.height = pixelSize.height;
    }

    if (!!changes['context'] || !!changes['size']) {
      this.updateHeight();
    }
  }

  ngOnDestroy() {
  }

  onMouseEnter() {
    if (this.interactService.isDragging() && this.hasNodes && !this.context.needsSave) {
      this.dragoverTimer = window.setTimeout(() => this.navigationService.navigateToNode(this.context.id), 50);
    }
  }

  onMouseLeave() {
    this.processDiv.nativeElement.removeAttribute(DATA_POINTER_OVER);
    window.clearTimeout(this.dragoverTimer)
  }

  getOwner(isTouching: boolean): string {
    return isTouching ? undefined : this.owner;
  }

  textUpdate(): void {
    this.updateHeight();
  }

  updateHeight(): void {
    MICRO_TASK.then(() => {
      const measuredHeight = this.processDiv.nativeElement.offsetHeight;
      if (this.measuredHeight !== measuredHeight) {
        this.measuredHeight = measuredHeight;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  hasLinks(): boolean {
    return !!this.context.info || !!this.context.extendedInfo || this.context.links?.filter(
      link => link.adopted === false && link.linkType !== 'watermark' && link.linkType !== 'logo').length > 0;
  }

  toggleEdit(edit: boolean) {
    this.activationChange.emit({
      element: this.context,
      type: edit ? 'textEdit' : 'active'
    });
  }

  preventIfNotEditing($event) {
    if (!this.isEditingText) {
      this.prevent($event);
    }
  }

  prevent($event) {
    if ($event) {
      if ($event.preventDefault) {
        $event.preventDefault();
      }
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
    }

    return false;
  }

  protected deactivate = () =>  {
    this.activationChange.emit({element: this.context, type: null});
  }

  toggleActive($event ?: Event | any) {
    if (!this.isActive) {

      if (this.context.activation !== 'textEdit') {
        this.activationChange.emit({element: this.context,type: 'active'});
      } else {
      }
    } else {
      this.updateElement();
      this.deactivate();
    }

    this.changeDetectorRef.markForCheck();
  }

  linkClicked(link: Link | string) {
    this.linkClick.emit({
      element: this.context,
      link: link
    });
  }

  private calculateActive(activationType: ActivationType): boolean {
    return activationType && activationType !== 'highlight';
  }

  private updateElement() {
    const html = this.trumbowyg.html;
    if (!!html && this.context.label !== html) {
      this.updated.emit({
        element: this.context,
        content: html
      });
    }
  }
}
