import {ActionReducerMap, combineReducers, createFeatureSelector, createSelector} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {isTemplateId} from '../../shared/model/template-id';
import {TreeWithLanguages} from '../../shared/model/tree';
import {renderOpenRows} from '../../shared/tree-building/tree-view-rendererer';
import * as fromAuth from './auth.reducer';
import {FavoritesState, favoritesStoreReducer, getFavoriteList, getFavorites} from './favorites.reducer';
import {FormattingState, formattingStoreReducer} from './formatting.reducer';
import {ImageTempState, tempImageReducer} from './image.reducer';
import {logbookSaveReducer, LogbookSaveState} from './logbook-saving';
import * as fromLoginPage from './login-page.reducer';
import {templatesReducer, TemplatesState} from './templates.reducer';
import * as fromTree from './tree.reducer';
import {getHistoryLabelActions} from './tree.reducer';
import * as fromViewOptions from './view-options.reducer';
import {ViewOptionsState} from './view-options.reducer';

export {HighlightSelectionType} from './view-options.reducer';

export interface AuthState {
  status: fromAuth.UserState;
  loginPage: fromLoginPage.LoginPageState;
}

export interface AppState {
  tree: fromTree.TreeState;
  formatting: FormattingState;
  favorites: FavoritesState;
  tempImage: ImageTempState;
  logbookSaving: LogbookSaveState;
  templates: TemplatesState;
  viewOptions: ViewOptionsState;
  auth: AuthState;
}

export const reducers: ActionReducerMap<AppState> = {
  tree: fromTree.treeStoreReducer,
  formatting: formattingStoreReducer,
  favorites: favoritesStoreReducer,
  tempImage: tempImageReducer,
  logbookSaving: logbookSaveReducer,
  templates: templatesReducer,
  viewOptions: fromViewOptions.viewOptionsReducer,
  auth: combineReducers<AuthState>({
    status: fromAuth.authReducer,
    loginPage: fromLoginPage.loginPageReducer,
  })
};

export const selectTreeState = createFeatureSelector<fromTree.TreeState>('tree');
export const selectTempImageState = createFeatureSelector<ImageTempState>('tempImage');
export const logBookSavingState = createFeatureSelector<LogbookSaveState>('logbookSaving');
export const templatesState = createFeatureSelector<TemplatesState>('templates');
export const selectViewOptionsState = createFeatureSelector<ViewOptionsState>('viewOptions');

export const getTree = createSelector(selectTreeState, fromTree.getTree);
export const getTreeRoot = createSelector(getTree, fromTree.getRoot);
export const getTreeLabels = createSelector(getTree, tree => tree.labels);
export const getTreeLabelArray = createSelector(getTreeLabels, labels => labels.valueSeq().toArray());
export const getTreeHistory = createSelector(selectTreeState, fromTree.getHistory);
export const getTreeOpenId = createSelector(selectTreeState, fromTree.getOpenId);
export const getTreeLoading = createSelector(selectTreeState, fromTree.isLoading);
export const getTreeNodeActivation = createSelector(selectTreeState, fromTree.getNodeActivation);
export const getTreeAnyNodeActive = createSelector(getTreeNodeActivation, (activation) => !!activation?.type && activation.type !== 'highlight');
export const getTreeActiveLanguage = createSelector(selectTreeState, fromTree.getActiveLanguage);
export const getTreeDefaultLanguage = createSelector(getTreeActiveLanguage, (lang) => lang?.isDefault || false);

export const getTreeNodes = createSelector(getTree, fromTree.getNodes);
export const getTreeLinks = createSelector(getTree, fromTree.getLinks);
export const getTreeOpenRows = createSelector(getTree, getTreeOpenId, fromTree.getOpenRows);
export const getTreeHasOpenUnsavedNodes = createSelector(getTree, getTreeOpenRows, getTreeOpenId, fromTree.hasOpenUnsavedNodes);

export const getTreePath = createSelector(getTree, fromTree.getPath);
export const getTreeHasUndo = createSelector(getTreeHistory, fromTree.hasUndo);
export const getTreeHasRedo = createSelector(getTreeHistory, fromTree.hasRedo);
export const getTreeSaving = createSelector(selectTreeState, state => state.saving);
export const getTranslationHighlight = createSelector(selectViewOptionsState, fromViewOptions.getTranslationHighlight);
export const getLabelsSettings = createSelector(selectViewOptionsState, fromViewOptions.getLabelsSettings);
export const getSmallLabels = createSelector(selectViewOptionsState, fromViewOptions.getSmallLabels);
export const getTreeHistoryActions = createSelector(getTreeHistory, fromTree.getHistoryActions);
export const getTreeActiveInheritedDetails = createSelector(getTree, getTreeNodeActivation,
  fromTree.getActiveInheritedDetails);
export const getTreeAllRootLogoSettings = createSelector(getTreeRoot, getTreeLinks, fromTree.getAllLogoSettings);
export const getTreeAllRootWatermarkSettings = createSelector(getTreeRoot, getTreeLinks,
  fromTree.getAllWatermarkSettings);
export const getTreeSource = createSelector(getTree, tree => tree.source);

export const getActiveRootLogoSettings = createSelector(getTreeAllRootLogoSettings, fromTree.getLogoSettings);
export const getActiveRootWatermarkSettings = createSelector(getTreeAllRootWatermarkSettings,
  fromTree.getWatermarkSettings);
export const selectFavoritesState = createFeatureSelector<FavoritesState>('favorites');
export const selectFavorites = createSelector(selectFavoritesState, getFavorites);
export const selectFavoritesAsList = createSelector(selectFavoritesState, getFavoriteList);

export const selectFormattingState = createFeatureSelector<FormattingState>('formatting');
export const getFormattingActive = createSelector(selectFormattingState, state => state.formatting);
export const getFormattingSource = createSelector(selectFormattingState, state => state.formatSourceId);
export const getFormattingSourceNode = createSelector(selectFormattingState, getTreeNodes, (state, nodes) => ({
  active: !!state.formatting,
  node: nodes?.get(state.formatSourceId)
}));

export const getTreeHasUndoNotTextEditing = createSelector(getTreeHasUndo, getTreeNodeActivation, (hasUndo, activeNode) => {
  return hasUndo && (!activeNode || activeNode.type !== 'textEdit')
});

export const getTemplateNode = createSelector(selectTreeState, (treeState) => treeState.templateNode);

export const getTreeNode = (id: string) => createSelector(getTreeNodes, nodes => nodes.get(id));

export const getRenderedOpenRows = createSelector(selectTreeState, getTranslationHighlight, getFormattingSource,
  getTreeOpenRows, selectViewOptionsState, renderOpenRows);

export const getLogbookSavedSinceLogging = createSelector(logBookSavingState, (state: LogbookSaveState) => state.hasSavedSinceLastLog);
export const getTemplatesShowing = createSelector(templatesState, (state: TemplatesState) => state.templatesShowing);
export const getTemplatesEnabled = createSelector(templatesState, (state: TemplatesState) => state.templatesEnabled);
export const getLastActiveTemplateSource = createSelector(templatesState, (state: TemplatesState) => state.lastTemplateSource);
export const getLastActiveNonTemplateSource = createSelector(templatesState, (state: TemplatesState) => state.lastNonTemplateSource);
export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(selectAuthState, (state: AuthState) => state && state.status || {
  loggedIn: false,
  isOnline: false,
  siteSettings: null,
  user: null,
  userIsPublic: false
});

export const getAuthLoggedIn = createSelector(selectAuthStatusState, fromAuth.getLoggedIn);
export const getAuthUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getAuthUserStartNode = createSelector(getAuthUser, user => user && user.startId || undefined);
export const getAuthUserIsPublic = createSelector(selectAuthStatusState, fromAuth.getUserIsPublic);
export const getAuthPasswordUpdatable = createSelector(selectAuthStatusState, fromAuth.getPasswordUpdatable);
export const getAuthUserIsOnline = createSelector(selectAuthStatusState, (state) => state.isOnline);
export const getAuthDomain = createSelector(selectAuthStatusState, fromAuth.getDomain);
export const getAuthUserRoles = createSelector(selectAuthStatusState, fromAuth.getUserRoles);
export const getAuthSiteLanguages = createSelector(selectAuthStatusState, fromAuth.getSiteLanguages);
const getAuthIsAuthor = createSelector(getAuthUserRoles, getAuthUserIsOnline,
  (userRoles, isOnline) => environment.canEdit && isOnline && userRoles.indexOf('edit') > -1);
export const getAuthIsAdmin = createSelector(getAuthUserRoles, getAuthUserIsOnline,
  (userRoles, isOnline) => environment.canEdit && isOnline && userRoles.indexOf('admin') > -1);
export const getAuthIsAuthorOrAdmin = createSelector(getAuthIsAdmin, getAuthIsAuthor,
  (author, admin) => author || admin);
export const selectLoginPageState = createSelector(selectAuthState, (state: AuthState) => state.loginPage);
export const getLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);
export const getLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);
export const getLoginPageReturnUrl = createSelector(selectLoginPageState, fromLoginPage.getReturnUrl);
export const getLoginPageDomain = createSelector(selectLoginPageState, fromLoginPage.getDomain);
export const getLoginPageLocal = createSelector(selectLoginPageState, fromLoginPage.getLocal);
export const getLoginPageTemporaryPassword = createSelector(selectLoginPageState, fromLoginPage.getTemporaryPassword);

export const getCurrentTreeLanguages = createSelector(getTreeSource, getAuthSiteLanguages, getTree,
  (source, siteLangs, tree) => {
    if (isTemplateId(source)) {
      return  (<TreeWithLanguages>tree).languages || []
    } else {
      return siteLangs;
    }
  });

export const getLabelIsUsedInPastHistory = (labelId) => createSelector(getTreeHistoryActions,
  (actions) => fromTree.getHistoryLabelActions(actions)
    .filter(action => action.payload.labelIds.includes(labelId)).length > 0);

export const getLabelIsUsedInFutureHistory = (labelId) => createSelector(getTreeHistory,
  (history) => getHistoryLabelActions(history.futureStates.map(state => state.action))
    .filter(action => action.payload.labelIds.includes(labelId)).length > 0);
