<div cdkDrag cdkDragBoundary="html" cdkDragRootElement=".cdk-overlay-pane" class="mat-typography dialog-content" [class.folded]="folded">
  <pm-foldable-dialog-title mat-dialog-title (foldedChange)="folded = $event">{{'dialog.publish-error.title' | translate}}</pm-foldable-dialog-title>
  <mat-dialog-content>
    <div class="link-check-description">{{'dialog.publish-error.description' | translate}}</div>
    <div class="link-check-description mat-small">{{'dialog.publish-error.description.list' | translate}}</div>
    <table mat-table class="pm-show-active" [dataSource]="dataSource">
      <ng-container matColumnDef="label">
        <th mat-header-cell class="proman-search-header" *matHeaderCellDef> {{'dialog.publish-error.table.label' |
          translate}}
        </th>
        <td mat-cell *matCellDef="let element" [title]="element.label"> {{element.label}}
        </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef> {{'dialog.publish-error.table.path' | translate}}</th>
        <td mat-cell class="proman-search-path" *matCellDef="let element"
            [title]="element.path"> {{element.path}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> {{'dialog.publish-error.table.type'  | translate}}</th>
        <td mat-cell class="proman-search-path" *matCellDef="let element"
            [title]="element.link"> {{'dialog.publish-error.table.type.' + element.extraData.key |translate}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToNode(row)"
          [class.active]="row.id === activeSearchElement"></tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="">{{'button.close' | translate}}</button>
  </mat-dialog-actions>
</div>
