<form (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>
  <input type="hidden" id="pm_domain" name="domain" [ngModel]="domain"/>
  <p class="domain-label mat-typography">{{'login.chosen-domain' | translate:({domain: domain})}}</p>
  <div class="form-field-container">
    <mat-form-field appearance="outline">
      <mat-label>{{'login.username' | translate}}</mat-label>
      <input autocomplete="username" matInput id="pm_username" name="username"  ngModel [attr.autofocus]="true" autofocus required/>
    </mat-form-field>
  </div>
  <div class="form-field-container">
    <mat-form-field appearance="outline">
      <mat-label>{{'login.password' | translate}}</mat-label>
      <input autocomplete="current-password" matInput id="pm_password" name="password" type="password" ngModel required/>
    </mat-form-field>
  </div>
  <button [disabled]="pending" mat-raised-button class="login-button">{{'login.submit' | translate}}</button>
  <div *ngIf="error" class="alert alert-danger">{{errorMessage}}</div>
</form>
<a class="routerLink mat-typography" [routerLink]="" queryParamsHandling="preserve" (click)="resetPassword()">{{ 'login.reset.link' | translate}}</a>
<a class="routerLink mat-typography" [routerLink]="" queryParamsHandling="preserve" (click)="chooseDomain()">{{ 'login.domain.link' | translate}}</a>
