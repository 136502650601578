import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {mergeMap, Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AppState, getTreeActiveLanguage} from '../../state-management/reducers';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {
  language: number;

  constructor(private store: Store<AppState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(getTreeActiveLanguage).pipe(
      first(),
      mergeMap(language => {
        if (!!language && req.url.startsWith(environment.api)) {
          const httpRequest = req.clone({
            setParams: {
              'language': String(language.id)
            }
          });
          return next.handle(httpRequest);
        } else {
          return next.handle(req);
        }
      })
    )
  }
}
