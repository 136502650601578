import {ADD_LOGBOOK_ENTRY, AddLogbookEntry} from '../actions/logbook-saving.actions';
import {SaveStepsComplete, TreeActionTypes} from '../actions/tree.actions';
import {Action} from '@ngrx/store';

export interface LogbookSaveState {
    hasSavedSinceLastLog: boolean;
}

const initialState: LogbookSaveState = {
    hasSavedSinceLastLog: false
}

export const CLEAR_LOGBOOK_SAVE_STATE = '[Logbook saving] Clear state';

export class ClearLogBookSaveState implements Action {
  type = CLEAR_LOGBOOK_SAVE_STATE;
}

export function logbookSaveReducer(state = initialState, action: ClearLogBookSaveState | AddLogbookEntry | SaveStepsComplete) {
    switch (action.type) {
        case CLEAR_LOGBOOK_SAVE_STATE:
        case ADD_LOGBOOK_ENTRY:
            return {...state, hasSavedSinceLastLog: false};
        case TreeActionTypes.SAVE_STEPS_COMPLETE:
            return {...state, hasSavedSinceLastLog: true};
        default:
            return state;
    }
}

