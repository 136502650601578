<div cdkDrag cdkDragBoundary="html" cdkDragRootElement=".cdk-overlay-pane" class="mat-typography" [class.folded]="folded">
  <pm-foldable-dialog-title mat-dialog-title (foldedChange)="this.onFoldedChange($event)">{{'dialog.search.title' | translate}}</pm-foldable-dialog-title>
  <div mat-dialog-content>
    <form>
      <mat-form-field class="search-field">
        <input matInput type="text" placeholder="{{'dialog.search.search' | translate}}" [formControl]="searchTextControl"
               name="searchField" tabindex="0" autofocus="autofocus">
        <mat-hint>{{'dialog.search.minimum-characters' | translate}}</mat-hint>
        <mat-spinner matSuffix [class.proman-visible]="loading" [diameter]="18"></mat-spinner>
      </mat-form-field>
      <mat-radio-group [formControl]="searchTypeControl" name="typeField">
        <div class="column">
          <span class="proman-header-label">{{'dialog.search.search-in.label' | translate}}</span>
          <mat-radio-button
            value="text">{{'dialog.search.search-in.processes-and-bullets' | translate}}</mat-radio-button>
          <mat-radio-button
            value="process">{{'dialog.search.search-in.processes' | translate}}</mat-radio-button>
          <mat-radio-button
            value="bullet">{{'dialog.search.search-in.bullets' | translate}}</mat-radio-button>
        </div>
        <div class="column">
          <mat-radio-button value="file">{{'dialog.search.search-in.document-names' | translate}}</mat-radio-button>
          <mat-radio-button value="info">{{'dialog.search.search-in.info' | translate}}</mat-radio-button>
        </div>
      </mat-radio-group>
    </form>
    <mat-table class="mat-elevation-z4 pm-show-active" #table [dataSource]="dataSource" matSort matSortActive="label"
               matSortDirection="asc" matSortDisableClear>
      <!-- Position Column -->
      <ng-container matColumnDef="label">
        <mat-header-cell class="proman-search-header" *matHeaderCellDef mat-sort-header> {{'dialog.search.table.label' |
          translate}}
        </mat-header-cell>
        <mat-cell class="proman-search-label" *matCellDef="let element" [title]="element.label"> {{element.label}}
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="path">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'dialog.search.table.path' | translate}}</mat-header-cell>
        <mat-cell class="proman-search-path" *matCellDef="let element"
                  [title]="element.path"> {{element.path}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToNode(row)"
               [class.active]="row.id === activeSearchElement"></mat-row>
    </mat-table>
  </div>
</div>
