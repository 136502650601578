import {NodeType, TreeElement} from './treeelement';

export const convertToPixelSize = (size: Size): Size => {
  if (!size) {
    return new Size(Size.BASE_WIDTH, Size.BASE_HEIGHT);
  }
  return new Size(!!size.width ? Size.BASE_WIDTH * size.width : Size.BASE_WIDTH,
    !!size.height ? Size.BASE_HEIGHT * size.height : Size.BASE_HEIGHT);
};

export const convertToNormalizedSize = (size: Size): Size => {
  return new Size(!!size.width ? size.width / Size.BASE_WIDTH : 1, !!size.height ? size.height / Size.BASE_HEIGHT : 1);
};

export function isProcess(treeElement: TreeElement): treeElement is Process {
  const process = (<Process>treeElement);
  return process && process.type && process.type !== 'bullet' || false;
}

export class Size {
  public static readonly BASE_WIDTH = 100;
  public static readonly BASE_HEIGHT = 50;

  constructor(public width: number, public height: number) {
  }
}

export interface Process extends TreeElement {
  type: NodeType; // Must be specified for processes
  childSize?: Size;
  bullets?: string[];
  nodes?: string[];
  hasUnloadedChildren?: boolean;
}
