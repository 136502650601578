<div class="pm-login-page">
  <header></header>
  <div class="pm-login-container">
    <div class="logo-container">
      <app-processmanager-logo></app-processmanager-logo>
    </div>

    <pm-domain-form *ngIf="state === 'domain'" (domain)="onDomainChosen($event)" (reset)="onReset()" [error]="translatedError$ | async" ></pm-domain-form>
    <pm-login-form *ngIf="state === 'login'" [domain]="domain$ | async" [error]="translatedError$ | async" [pending]="pending$ | async"
                   [returnUrl]="returnUrl$ | async" (authenticated)="onAuthenticated($event)" (reset)="onReset()" (backToDomain)="onBackToDomains()"></pm-login-form>
    <pm-login-reset-form *ngIf="state === 'reset'" [domain]="domain$ | async" [error]="translatedError$ | async"
                         [pending]="pending$ | async" (resetRequest)="onResetRequest($event)"
                         (cancelRequest)="onResetCancel()"></pm-login-reset-form>
  </div>
  <footer></footer>
</div>
